<template>
  <div class="ChangePassword div-template">

    <notification-popup  
    :dialog="notifSuccess"
    :contentMsg="$t('succesfully')"
    :headerMsg="$t('succesfully')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "success"
    typeModal="transaction"
    Icon="mdi-check-circle"
    :onHandlerNo="cancelSuccess"
    :onHandlerYes="buttonOke"/>


    <notification-popup 
    :dialog="notifFailed"
    :contentMsg="$t('notifFailedChangePassword')"
    :headerMsg="$t('failed')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
      :onHandlerNo="cancelFailed"
    :onHandlerYes="buttonOkeFailed"/>

    <h4 class="h4-title"> {{$t('titleChangePassword')}}</h4>
    <form class="div-template">

      <v-text-field
        v-model="new_password" 
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showNewPassword ? 'text' : 'password'"
        @click:append="showNewPassword = !showNewPassword"
        :label="$t('labelNewPassword')"
          outlined
          :isDisabled="false"
          :isClearable="true"
          :rules="rules"
      ></v-text-field>

      <v-text-field
      v-model="re_type_new_password" 
      :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showConfirmPassword ? 'text' : 'password'"
      @click:append="showConfirmPassword = !showConfirmPassword"
        :label="$t('labelConfirmPassword')"
        outlined
        :isDisabled="false"
        :isClearable="true"
        :rules="rules"
      ></v-text-field>

      <ac-button
        :name="$t('titleChangePassword')" 
        :color="primary"
        :disabled="old_password == '' && new_password == '' && re_type_new_password == '' ? true : false"
        :onClickHandler="clickUpdatePassword"
      />
      <!-- <v-btn 
        rounded
          name="Done" 
          color="primary"
          @click="clickBackDone()">
          Done
        </v-btn> -->
    </form>
    </div>
</template>
<script>
import AcButton from '../../components/AcButton.vue'
import NotificationPopup from "@/components/NotificationPopup.vue";
// import axios from "axios";

  export default {
    name: 'ChangePassword',
    //props: {    data: Object,    required: true  },
    components: {NotificationPopup, AcButton,  },
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,
        showNewPassword: false,
        showConfirmPassword: false,
        disabledBtn:"",
        new_password:"",
        re_type_new_password:"",
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 50 || 'Max 50 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      }
    },
    methods:{
      cancelFailed(){
        this.notifFailed=false;
      },
      cancelSuccess(){
        this.notifSuccess=false;
      },
      clickUpdatePassword(){
        
        const payload = {
          otp_value : this.$route.params.sc,
          user_id: this.$route.params.id,
          new_password: this.new_password,
          confirm_password: this.re_type_new_password,
        };
        console.log("payload ",payload)
        if (payload.new_password.length < 6) {
            this.notifFailed = true
        } else {
          this.$store
          .dispatch("passenger/resetPass", payload)
          .then((response) => {
          console.log("response ",response)
            if (response.status == true) {
              this.notifSuccess = true
            }else{
              this.notifFailed = true
            }

          })
          .catch((err) => {
            this.notifFailed = true
            console.log(err);
          });
        }
      },
      buttonOke(){
        //this.notifSuccess = false
        if (this.notifSuccess == true) 
        {
          this.$router.push({ name: "Login" })
        }else{
          this.notifFailed = true
        }
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
    }
  }
</script>

<style lang="scss" scoped>
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.h4-title{
  padding-top: 10px;
  padding-left: 10px;
}


</style>